import React from 'react';
import { Helmet } from 'react-helmet-async';

import { MainPageImage } from '../ImageMainPage/ImageMainPage';
import { DiscountCarusel } from '../OrdersManager/DiscountCarusel/DiscountCarusel';
import { OuterCatalogCardsContainer } from '../OuterCatalogCardsContainer/OuterCatalogCardsContainer';

import classes from './MainPage.module.scss';

export function MainPage({
  stateToRerenderBuyButton,
  setStateToRerenderBuyButton,
  setShoppingCardPreview,
  makeMainContentUnscrollable,
}) {
  return (
    <div className={classes.mainPageContainer}>
      <Helmet>
        <meta
          name='description'
          content='Купити пряжу в інтернет-магазині Wooolama. Пряжа Alize, Nako, YarnArt, Madame Tricote, Gazzal в моточках поштучно і бобінна пряжа на розмотку від 50 грам'
        />
        <link rel='canonical' href={`https://wooolama.com`} />
      </Helmet>
      <MainPageImage />
      <OuterCatalogCardsContainer />
      <DiscountCarusel
        stateToRerenderBuyButton={stateToRerenderBuyButton}
        setStateToRerenderBuyButton={setStateToRerenderBuyButton}
        setShoppingCardPreview={setShoppingCardPreview}
        makeMainContentUnscrollable={makeMainContentUnscrollable}
      />
    </div>
  );
}
